import React from 'react';

// Pages
import Home from './home';
import Bootstrap from '../components/bootsrap';

export default () => {
  return (
    <Bootstrap animatedBg={true}>
      <Home />
    </Bootstrap>
  );
};
